<template>
  <div class="block">
    <el-date-picker
      v-model="datetime"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format=" yyyy-MM-dd"
      format="yyyy-MM-dd"
      @change="dateChangebirthday"
      @input="immediUpdate"
      :picker-options="pickerOptions"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      datetime: "",
    };
  },
  methods: {
    dateChangebirthday(val) {
      if(val){
        this.startTime = val;
        this.$emit("get-date", [this.startTime[0], this.startTime[1]]);
      }
    },
    immediUpdate(e){
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.$emit("get-date", ['', '']);
        }
      });
    },
  },
};
</script>
