<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item
                    >
                    <el-breadcrumb-item>联盟商家</el-breadcrumb-item>
                    <el-breadcrumb-item>商品审核</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="经营类型">
                            <el-select
                                    v-model="where"
                                    placeholder="请选择"
                                    clearable
                                    style="width: 100px"
                            >
                                <el-option label="全部" value=""></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属区域">
                            <el-select v-model="where" style="width: 100px">
                                <el-option label="全部" value=""></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="关键字">
                            <el-input
                                    v-model="where.keyWord"
                                    placeholder="门店名称/ID"
                                    clearable
                                    style="width: 150px"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="加入时间">
                            <date-picker @get-date="getDate"></date-picker>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="where.queryIndex" style="width: 100px">
                                <el-option label="待审核" value="0"></el-option>
                                <el-option label="审核通过" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <!--<el-button
                              type="primary"
                              @click="exportData"
                              disabled
                              icon="el-icon-tickets"
                              >导出数据</el-button
                            >-->
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange">
                    <el-table-column prop="merchantId" label="商家ID"></el-table-column>
                    <el-table-column
                            prop="merchantName"
                            label="商家名称"
                    ></el-table-column>
                    <el-table-column prop="shopId" label="门店ID"></el-table-column>
                    <el-table-column prop="shopName" label="门店名称"></el-table-column>
                    <el-table-column
                            prop="categoryName"
                            label="经营类型"
                    ></el-table-column>
                    <el-table-column
                            prop="areaLocation"
                            label="所属区域"
                    ></el-table-column>
                    <el-table-column
                            prop="givingPercent"
                            label="赠豆比例%"
                    ></el-table-column>
                    <el-table-column prop="applyCount" label="待审数量"></el-table-column>
                    <el-table-column
                            prop="noPassCount"
                            label="不通过数量"
                    ></el-table-column>
                    <el-table-column
                            prop="createTime"
                            label="最后更新时间"
                    ></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    type="primary"
                                    icon="el-icon-edit"
                                    @click="
                  $router.push({
                    name: 'mcauditlist-goods',
                    query: { id: scope.row.shopId },
                  })
                "
                            >查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import datePicker from "@/components/admin/date-picker.vue";

    export default {
        components: {
            datePicker,
        },
        props: {
            orderStatus: Number,
        },
        data() {
            return {
                list: [],
                skuData: "",
                isShowSku: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                selectId: "",
                goods_verify_count: 0,
                where: {
                    applyDateEnd: "",
                    applyDateStart: "",
                    pageNum: 1,
                    pageSize: 0,
                    queryIndex: "1",
                },
            };
        },
        methods: {
            listData() {
                this.where.pageSize = this.pageSize;
                this.where.limit = this.pageSize;
                this.where.shopId = this.shopId;
                this.where.pageNum = this.currentPage;
                this.$post(this.$api.commodityApproveList, this.where).then((res) => {
                    if (res.code == 1) {
                        this.totalData = res.data.count;
                        this.list = res.data.records;
                    }
                });
            },
            orderStatusData() {
                this.$post(this.$api.orderStates, {}).then((res) => {
                    if (res.code == 1) {
                        this.orderStatusList = res.data;
                    }
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v);
                });
                this.selectId = ids;
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            getDate(obj) {
                if (obj) {
                    this.where.applyDateStart = obj[0];
                    this.where.applyDateEnd = obj[1];
                }
            },
        },
        created() {
            this.listData();
        },
    };
</script>
